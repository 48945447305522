<template>
  <div>
    <Menu></Menu>
    <div class="w1200 m-auto">
      <p class="fs14 color-1 mt10"><span @click="$router.push('/')" class="index">首页</span> > 专业能力 >{{ title }}</p>
      <div class="contend mt20">
        <div>
          <div class="TopBox">
            专业能力
          </div>
          <div class="mt20 fs16 contendLeft">
            <ul>
              <li @click="skip('/occupationalRequirements', 1, '考试列表')" :class="num == 1 ? 'active' : ''">考试列表</li>
              <li @click="skip('/occupationalRequirements/ScoreQuery', 2, '成绩查询')" :class="num == 2 ? 'active' : ''">
                成绩查询
              </li>
              <li @click="skip('/occupationalRequirements/VideoCourse', 3, '视频课程')" :class="num == 3 ? 'active' : ''">
                视频课程</li>
              <li @click="skip('/occupationalRequirements/PublicityApplication',4,'宣传申领')"
                :class="num == 4 ? 'active' : ''">
                宣传申领
              </li>
            </ul>
          </div>
        </div>
        <router-view></router-view>

      </div>
    </div>

  </div>
</template>

<script>
import Menu from '@/components/menu.vue'
export default {
  components: { Menu, },
  data() {
    return {
      num: 1,
      title: '考试列表'
    }
  },
  created() {
    this.num = localStorage.getItem('num') ? localStorage.getItem('num') : 1
    this.title = localStorage.getItem('title') ? localStorage.getItem('title') : '考试列表'
  },
  mounted() {

  },
  methods: {
    skip(url, num, title) {
      this.title = title
      this.num = num
      this.$router.push(url)
      localStorage.setItem('title', this.title)
      localStorage.setItem('num', num)
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('num')
    localStorage.removeItem('title')
    next()
  }
}
</script>

<style  scoped>
.contend {
  display: flex;
  justify-content: space-between;
}

.contend>div:nth-child(1) {
  width: 260px;
}

.contend>div:nth-child(1) div:nth-child(1) {
  width: 260px;
  height: 120px;
  background: url(../../../public/image/zuohongbg.png);
  text-align: center;
  border: 1px solid #E6E6E6;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100px;
}

.contend>div:nth-child(1) div:nth-child(2) {
  width: 260px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  color: #333333;
}

.contendLeft {
  padding: 20px;
  box-sizing: border-box;
}

.contendLeft li {
  padding: 5px 30px;
  margin: 10px 0px;
  cursor: pointer;
  font-size: 16px;
}

.contend>div:nth-child(2) {
  width: 920px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

}

.active {
  color: #CC0000;
  background: #FFF5F5;
  position: relative;
}

.active::before {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  left: 15px;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  border: 6px;
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent #CD0000;
}

.index {
  font-weight: 700;
  text-decoration: none;
  transition: color .2s cubic-bezier(.645, .045, .355, 1);
  color: #303133;
  cursor: pointer;
}
</style>